<header>
  <nav class="navbar col-12 justify-content-between h-100 p-0">
    <!--  TODO  SMART.upt.ro  see if new logo is required or add smart word-->
    <a class="logo p-0 h-100 text-xl-right" routerLink="/">
      <img src="assets/dxworks.png" alt="dxWorks"  class="h-100">
    </a>
    <div class="d-flex flex-row mx-1">
    </div>
  </nav>
</header>
